import React, { useEffect } from 'react';
import { Container, Grid, Stack } from '@mantine/core';
import { Button } from '@unserkunde/enscompare-components/src/components/components';
import { FaArrowRight } from 'react-icons/fa';
import { useAppDispatch, useAppSelector, useDynamicFields, useOnAppReady } from '../../hooks';
import { submitOfferRequestProcess } from '../../actions/progress';
import { InitilizeFilterSettings } from './OfferRequestProcessFilterSettings';
import OfferReqestProcessInputs from './OfferReqestProcessInputs';
import { useDefaultSettings } from './DefaultSettingsLoader';
import DynamicEntryQuestion from 'forms/shared/DynamicEntryQuestion';
import { useOfferRequestSettings } from 'processes/offerRequestProcess/IsOfferRequestProcess';
import {
  ProceduralOverviewInput,
  ProceduralPage,
  useIsProcedural,
} from '@/forms/OverviewControls/proceduralOverviewInput/ProceduralOverviewInput';
import { OverlayDisplay } from '../overlay/OverlayDisplay';
import { openOverlay } from '../overlay/overlayReducer';
import AngebotFertig from '@/forms/angebot/AngebotCompleted';
import { applyDefaultUserData } from '@/actions/data/requestOffers';

function ButtomButtonRow(props: { onContinue: () => void; nextBtnLabel?: string }) {
  const dispatch = useAppDispatch();
  const onGotoSettings = () => dispatch(openOverlay('offerRequestProcessSettings'));

  const offerRequestSettings = useOfferRequestSettings();

  return (
    <Grid>
      {offerRequestSettings.offerRequestNoSettings ? null : (
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            fullWidth
            variant='outline'
            onClick={onGotoSettings}>
            Einstellungen
          </Button>
        </Grid.Col>
      )}
      <Grid.Col
        xs={12}
        sm={6}>
        <Button
          color='secondary'
          fullWidth
          onClick={props.onContinue}>
          {props.nextBtnLabel ?? 'Weiter'}&nbsp;&nbsp;
          <FaArrowRight color='white' />
        </Button>
      </Grid.Col>
    </Grid>
  );
}

const EnsuranceTypeSelection = (props: { onContinue: () => void; onBack: () => void }) => {
  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);

  useEffect(() => {
    if (!instanceMode || instanceMode === 'animal') return;
    props.onContinue();
  }, [instanceMode, props]);

  return (
    <Stack
      spacing='md'
      align='stretch'>
      <OfferReqestProcessInputs />
      <ButtomButtonRow onContinue={props.onContinue} />
    </Stack>
  );
};

const OfferRequestProcessDefault = () => {
  const entryQuestions = useDynamicFields(null, (o) => o.displayPositions?.includes('offerrequestprocess'));
  const defaultBikeId = useAppSelector((state) => state.userData.defaultBikeId);

  const dispatch = useAppDispatch();
  const onSubmit = () => dispatch(submitOfferRequestProcess());

  return (
    <>
      <OfferReqestProcessInputs />
      {entryQuestions.map((e, i) => (
        <DynamicEntryQuestion
          bikeId={e.positionType === 'object' ? defaultBikeId : null}
          setting={e}
          key={i}
        />
      ))}
      <ButtomButtonRow
        nextBtnLabel='Angebote anfordern'
        onContinue={onSubmit}
      />
    </>
  );
};

const views: ProceduralPage[] = [
  {
    type: 'special',
    component: EnsuranceTypeSelection,
  },
  {
    questionsGetter: [
      null,
      (o) => o.displayPositions?.includes('offerrequestprocess') && o.name === 'deviceModeOverride',
    ] as const,
  },
  {
    questionsGetter: [
      null,
      (o) => o.displayPositions?.includes('offerrequestprocess') && o.positionType === 'object',
    ] as const,
    bikeIndex: 0,
  },
  {
    questionsGetter: [
      null,
      (o) =>
        o.displayPositions?.includes('offerrequestprocess') &&
        o.positionType === 'request' &&
        o.name !== 'deviceModeOverride',
    ] as const,
  },
];

const OfferRequestProcessProcedural = () => {
  const dispatch = useAppDispatch();
  const onSubmit = () => dispatch(submitOfferRequestProcess());

  return (
    <ProceduralOverviewInput
      view={views}
      onCompleted={onSubmit}
    />
  );
};

export const OfferRequestProcess = () => {
  useDefaultSettings();

  const dispatch = useAppDispatch();
  const isProceduralProgress = useIsProcedural();

  const overlayName = useAppSelector((state) => state.overlay.overlayName);
  const router = useAppSelector((state) => state.router);

  useOnAppReady(() => {
    InitilizeFilterSettings(dispatch);
    dispatch(applyDefaultUserData({ skipSavedCoreFilter: true }));
  });

  if (overlayName) return <OverlayDisplay />;

  if (router === 'angebotfertig') return <AngebotFertig />;

  return (
    <>
      <Container>
        {router}
        <Stack px={0}>
          <h2>Versicherungsangebot anfordern</h2>

          {isProceduralProgress ? <OfferRequestProcessProcedural /> : <OfferRequestProcessDefault />}
        </Stack>
      </Container>
    </>
  );
};

export default OfferRequestProcess;
