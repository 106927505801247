import React, { useCallback, useEffect, useMemo } from 'react';
import { Checkbox } from '@mantine/core';
import { useUserData } from '@/hooks';
import EnsFieldsSwitch from '@/forms/shared/Switches/EnsFieldsSwitch';

const OfferRequestProcessTypeInput = () => {
  const values = useMemo(
    () => [
      ['Krankenversicherung inkl. OP-Kosten', 'kvop'],
      ['Nur OP-Kosten', 'op'],
      ['Tierhalterhaftpflicht', 'thv'],
    ],
    []
  );

  const [type, setType] = useUserData('offerRequestProcessType', null);

  const typeAsArray = useMemo(() => (type || '').split(','), [type]);
  const onChange = useCallback((value) => setType(value.filter((e) => e).join(',')), []);

  useEffect(() => {
    if (!type) setType('kvop');
  }, []);

  return (
    <Checkbox.Group
      value={typeAsArray}
      onChange={onChange}
      label='Was möchtest du absichern'>
      {values.map(([label, value]) => (
        <Checkbox
          key={value}
          value={value}
          label={label}
        />
      ))}
    </Checkbox.Group>
  );
};

const OfferReqestProcessInputs = () => {
  return (
    <>
      <EnsFieldsSwitch
        ensField={'ens_instance_mode'}
        check={(value) => value === 'animal'}>
        <OfferRequestProcessTypeInput />
      </EnsFieldsSwitch>
    </>
  );
};
export default OfferReqestProcessInputs;
